interface VulContObj {
  level: number
  name: string
  total: number
  level__name_value:string
}

export interface ProjectListParams {
  id: number
  name: string
  mode: string
  agent_count: number
  latest_time: string
  vul_count: Array<VulContObj>
  status: number
  project_version_latest_time: any
  project_group_name: Array<string>
  online_agent_count: number
  agent_language: Array<string>
}

export interface ProjectObj {
  id: number
  mode: string
  name: string
  owner: string
  latest_time: string
  project_version_latest_time: any
  versionData: any
  agent_languag: Array<string>
  type_summary?: any
}

export const enum SelectTabs {
  desc = 'desc',
  vul = 'vul',
}
